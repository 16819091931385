import { MouseEventHandler, PropsWithChildren } from 'react'

import ArrowRight from '~/components/Icons/ArrowRight'
import Link from '~/components/Link'

import styles from './Button.module.css'

type BaseProps = {
  size?: 'small' | 'large'
  variant?: 'primary' | 'ghost' | 'outlined'
  className?: string
  withArrow?: boolean
}
type Props = BaseProps &
  (
    | {
        onClick: MouseEventHandler<HTMLButtonElement>
        href?: never
      }
    | {
        href: string
        onClick?: MouseEventHandler<HTMLAnchorElement>
      }
  )

export const Button = ({
  onClick,
  href,
  size = 'large',
  variant = 'primary',
  children,
  className,
  withArrow = true,
}: PropsWithChildren<Props>) => {
  const combinedClassName = `${styles.button} ${styles[size] ?? ''} ${styles[variant] ?? ''} ${className ?? ''}`
  const iconRight = withArrow ? <ArrowRight className={styles.iconRight} /> : null

  if (typeof href === 'string') {
    return (
      <Link className={combinedClassName} href={href}>
        {children}
        {iconRight}
      </Link>
    )
  } else {
    return (
      <button className={combinedClassName} onClick={onClick}>
        {children}
        {iconRight}
      </button>
    )
  }
}
