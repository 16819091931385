/* eslint-disable @next/next/no-img-element */
'use client'
import React, { useState } from 'react'

import Link from '~/components/Link'

import styles from './Header.module.css'

type HeaderLink = {
  href: string
  label: string
}

type Logo = {
  src: string
  alt: string
}

type Props = {
  middleLinks?: HeaderLink[]
  rightLink?: HeaderLink
  logo?: Logo
  theme?: typeof styles
}

type MenuItemProps = {
  label: string
  href: string
  theme: typeof styles
}

const MenuItem = ({ label, href, theme }: MenuItemProps) => {
  return (
    <Link className={theme.middleItem} href={href} passHref>
      <span className={theme.middleItem}>{label}</span>
    </Link>
  )
}

export const Header = ({
  middleLinks,
  rightLink,
  logo = { src: '/lp/assets/logo/logo-festival-rw.svg', alt: 'RW' },
  theme = styles,
}: Props) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false)
  const mergedTheme = { ...styles, ...theme }

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen)
  }

  return (
    <div className={`container ${mergedTheme.container}`}>
      <div className={mergedTheme.left}>
        <img className={mergedTheme.logo} src={logo.src} alt={logo.alt} />
      </div>
      <div className={mergedTheme.middle}>
        {middleLinks?.map(({ label, href }) => <MenuItem key={label} label={label} href={href} theme={mergedTheme} />)}
      </div>
      <div className={mergedTheme.rightMobile} onClick={toggleMenu}>
        <div className={mergedTheme.hamburger}>☰</div>
      </div>
      <div className={mergedTheme.right}>
        {rightLink && <MenuItem label={rightLink?.label} href={rightLink?.href} theme={mergedTheme} />}
      </div>
      {isMenuOpen && (
        <div className={mergedTheme.overlay}>
          <div className={mergedTheme.overlayControls}>
            <img height='32px' className={mergedTheme.logoOverlay} src={logo.src} alt='RW' />
            <div className={mergedTheme.closeButton} onClick={toggleMenu}>
              X
            </div>
          </div>
          {middleLinks?.map(({ label, href }) => <MenuItem key={label} label={label} href={href} theme={mergedTheme} />)}
          {rightLink && <MenuItem label={rightLink?.label} href={rightLink?.href} theme={mergedTheme} />}
        </div>
      )}
    </div>
  )
}
