'use client'

import { ParamBuilder, ResizeType } from '@bitpatty/imgproxy-url-builder'

class ProxyParamBuilder extends ParamBuilder {
  private path: string

  constructor(path: string) {
    super()
    this.path = path
  }

  proxyBuild(): string {
    const isInternalAsset = this.path.startsWith('/lp/')
    if (isInternalAsset) return this.path

    const key = process.env.NEXT_PUBLIC_IMGPROXY_KEY
    const salt = process.env.NEXT_PUBLIC_IMGPROXY_SALT
    const baseUrl = process.env.NEXT_PUBLIC_IMGPROXY_URL

    const signature =
      key && salt
        ? {
            key,
            salt,
          }
        : undefined

    const buildData = {
      path: this.path.replace('https://', 'gs://'),
      baseUrl,
      plain: true,
      ...(signature && { signature }),
    }

    return super.build(buildData)
  }
}

const imgProxy = (path: string): ProxyParamBuilder => {
  return new ProxyParamBuilder(path)
}

export const imgProxyLoader = ({ src, width, quality }: { src: string; width: number; quality?: number }) => {
  const result = imgProxy(src).resize({ type: ResizeType.FILL, width })
  if (quality) result.quality(quality)

  return result.proxyBuild()
}

export default imgProxy
