'use client'
import React from 'react'

import Image from 'next/image'

import { formatPrice } from '~/utils/helpers'
import { imgProxyLoader } from '~/utils/imgproxy'

import { CollectionCategoryEnum } from '~/app/generated/graphql'

import { ReservableExtra, Restaurant } from '../CollectionSection'

import styles from './ReservationOfferCard.module.css'
import { ReservationOfferCardProps } from './ReservationOfferCardTypes'
import { getFERProperties, getReservableExtraProperties } from './ReservationOfferCardUtils'

export const ReservationOfferCard = ({
  item,
  category,
  type = 'regular',
  theme = styles,
  guestLabel = 'Guest',
}: ReservationOfferCardProps) => {
  const mergedTheme = { ...styles, ...theme }

  const properties =
    category === CollectionCategoryEnum.Reservableextra
      ? { ...getReservableExtraProperties(item as ReservableExtra) }
      : { ...getFERProperties(item as Restaurant) }
  const { eyebrowText, titleText, photo, slug, price } = properties

  return (
    <a className={mergedTheme.cardActions} href={category === CollectionCategoryEnum.Reservableextra ? `/c/${slug}` : `/r/${slug}`}>
      <div className={mergedTheme.styledTile}>
        <div
          className={`${mergedTheme.photoWrapper} ${type === 'regular' ? mergedTheme.photoWrapperDesktop : mergedTheme.photoWrapperMobile}`}>
          <div className={mergedTheme.photo}>
            <Image
              src={photo || '/lp/assets/generic/hero_background.png'}
              alt={titleText}
              className={mergedTheme.image}
              height={type === 'regular' ? 305 : 183}
              loader={imgProxyLoader}
              width={305}
              sizes='(max-width: 500px) 100vw,
                  (max-width: 1200px) 50vw,
                  33vw'
            />
          </div>
        </div>
        <div className={mergedTheme.infoBox}>
          <div className={mergedTheme.infoRow}>
            <h3 className={mergedTheme.infoTitle}>{titleText}</h3>
            <div className={mergedTheme.eyebrow}>{eyebrowText}</div>
          </div>
          {price && (
            <div className={mergedTheme.detailsContainer}>
              <h6 className={mergedTheme.heading6}>{formatPrice(price)}&nbsp;/&nbsp;</h6>
              <h6 className={mergedTheme.heading6Regular}>{guestLabel}</h6>
            </div>
          )}
        </div>
      </div>
    </a>
  )
}
